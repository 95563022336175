.infocontainer {
  width: 100%;
  transform: skew(-12deg);
  margin: 0;
}

.infocontainer {
  @apply px-0 md:px-4;
}

.infocontainer__background {
  width: 100%;
}

.infocontainer__content {
  background: #f2f0ef;
  padding: 20px;
  position: relative;
  transform: skew(-12deg);
}

.infocontainer > div {
  @apply relative transform skew-x-[12deg] px-0;
}

.infocontainer .w-full {
  @apply sm:px-0;
}

.infocontainer::before {
  @apply hidden md:block;
}

.infocontainer::before {
  background: #f2f0ef;
  bottom: 0;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 500%;
}

.infocontainer::after {
  @apply hidden md:block;
}

.infocontainer::after {
  background: #f0cc00;
  bottom: 0;
  content: "";
  height: 50%;
  position: absolute;
  right: -10px;
  width: 10px;
}
